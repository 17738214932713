import React from "react";
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useTranslation } from "react-i18next"
import Heading from "../Heading"
import './rooms.scss'

export default ({ data, rooms }) => {

    const { t } = useTranslation();
    const dateFormat = t('dateFormat')

    return(
        <div className="room-list-area pt-95 pb-100">
		    <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Heading title={t('best_room_deals')} subtitle={t('rooms_list_subtitle')} className="mb-50" />
                    </div>
                </div>
		        <div className="row">
		            <div className="col-12 room-container">
                        {rooms.map(({node: room}) => (
                            <div className="room-list-item" data-aos="fade-up">
                                <div className="row">
                                    <div className="col-lg-4 image-left">
                                        <div className="room-img img-full">
                                            <Img sizes={room.frontmatter.image.childImageSharp.fluid} />
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="room-list-content">
                                            <h3><Link to={room.frontmatter.slug}>{room.frontmatter.title}</Link></h3>
                                            <p dangerouslySetInnerHTML={{__html: data.html}} />
                                            <h4>{t('room_details')}</h4>
                                            <ul>
                                                <li>
                                                    <p>
                                                        <i className="fa fa-bed"></i> 
                                                        {t('bed')}:&nbsp; 
                                                        <span>
                                                             {room.frontmatter.bed}
                                                        </span>
                                                    </p>
                                                </li>
                                                {room.frontmatter.extraBed && (
                                                <li>
                                                    <p>
                                                        <i className="fa fa-bed"></i>
                                                        {t('extra_bed')}:&nbsp; 
                                                        <span>
                                                            {room.frontmatter.extraBed}
                                                        </span>
                                                    </p>
                                                </li>
                                                )}
                                                <li>
                                                    <p>
                                                        <i className="fa fa-binoculars"></i> 
                                                        {t('view')}:&nbsp; 
                                                        <span>
                                                             {room.frontmatter.view}
                                                        </span>
                                                    </p>
                                                </li>

                                                <li>
                                                    <p>
                                                        <i className="fa fa-wifi"></i> 
                                                        {t('wifi')}:&nbsp; 
                                                        <span>
                                                             {room.frontmatter.wifi}
                                                        </span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className="fa fa-coffee"></i> 
                                                        {t('breakfast')}:&nbsp; 
                                                        <span>
                                                             {room.frontmatter.breakfast}
                                                        </span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                        <i className="fa fa-users"></i> 
                                                        {t('max_guest')}:&nbsp; 
                                                        <span>
                                                             {room.frontmatter.max_guest}
                                                        </span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <img src="/images/spa-icon.svg" className="spa-icon" alt="" />
                                                    <strong>{t('wellness_and_spa')}</strong>
                                                </li>
                                            </ul>
                                            <div className="room-book-action">
                                                <div className="room-price">
                                                    <ul>
                                                        <li>
                                                            <p>{t('starting_rate')}:&nbsp; </p>
                                                        </li>
                                                        <li>
                                                            <p>
                                                                <span className="room_from">{t('room_from')} </span>
                                                                <span>{room.frontmatter.price}</span>
                                                                <span> {t('per_person_per_night')}</span>
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="book-room">
                                                    <Link className="primary-btn" to={room.frontmatter.slug}> {t('details')} <i className="fa fa-caret-right" aria-hidden="true"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 image-right">
                                        <div className="room-img img-full">
                                            <Img sizes={room.frontmatter.image.childImageSharp.fluid} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
		    </div>
        </div>
        
    )}